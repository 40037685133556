import React, { useState, useContext } from "react";
import "../style/rechargeSupme.css";
import { UserContext } from "../App";
import axios from "axios";
import TransactionRecHistory from "./TransactionRe_his";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import ListTypeCard from "../components/ListTypeCard";
import md5 from "md5";
export default function ScratchCard() {
  const { user } = useContext(UserContext);
  const [pin, setPin] = useState("");
  const [seri, setSeri] = useState("");
  const [cardType, setCardType] = useState("");
  const [cardValue, setCardValue] = useState("");
  const [loading, setLoading] = useState(false);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const API_KEY = "5f88e3df1d3d73e6c5cb109c3daf17d6fbcc6d1c";
  const cardTypes = [
    {
      value: "VIETTEL",
      label: "Viettel",
      imgSrc: require("../image/brands/viettel.jpg"),
    },
    {
      value: "MOBIFONE",
      label: "MobiFone",
      imgSrc: require("../image/brands/mobiphone.jpg"),
    },
    {
      value: "VINAPHONE",
      label: "VinaPhone",
      imgSrc: require("../image/brands/vinaphone.jpg"),
    },

    {
      value: "GARENA",
      label: "Garena (chậm)",
      imgSrc: require("../image/brands/garena.webp"),
    },
    {
      value: "ZING",
      label: "Zing",
      imgSrc: require("../image/brands/zing.webp"),
    },
    { value: "GATE", label: "Gate", imgSrc: require("../image/brands/gate.png") },
    {
      value: "VIETNAMOBILE",
      label: "Vietnamobile",
      imgSrc: require("../image/brands/vietnammobile.jpg"),
    },
   
  ];

  const handleCardTypeChange = (value) => {
    setCardType(value);
  };

  const formatSurplus = (value) => {
    return value?.toLocaleString("vi-VN");
  };

  const handleCardSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const transactionId = user?.data[0].UserName+seri;
    const sign = md5(API_KEY + pin + seri);
    const requestData = {
      telco: cardType,
      amount: parseInt(cardValue), // Mệnh giá phải là số
      serial: seri,
      code: pin,
      partner_key: API_KEY,
      transaction_id: transactionId,
      sign:sign,
      is_fast: 1,
    };
    try {
      const response = await fetch("https://doithecaoonline.com/api/partner/add-card", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
    });
    console.log("reques:",requestData);

    const result = await response.json();
    console.log("result: " , result);
    setLoading(false);
    if (result.status_code === 200) {
      
      const requestData = {
        UserName: user?.data[0].UserName, // Thay bằng tên người dùng thực tế
        Content: transactionId,
        Money: parseInt(cardValue), // Số tiền nạp
        id_type_chage: 2,
        codeTS: pin // Mã giao dịch hoặc thông tin cần thiết khác
      };
      try {
        const response = await fetch(API_BASE_URL+"/rechargeCard", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        });
    
        const result = await response.json();
    
        if (response.ok) {
          window.location.reload();
        } else {
          alert(`Giao dịch thất bại: ${result.error}`);
        }
      } catch (error) {
        console.error("Error:", error);
        alert("Có lỗi xảy ra khi gọi API.");
      }
      
    } else {
     
        alert(`Giao dịch thất bại: ${result.message}`);
    }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="tab-container container-fluid">
      <div className="row mb-2 gx-2">
        <div className="col-6 text-center">
          <b className="small">Ví tài khoản:</b>
          <b className="text-theme mb-0">
            <span id="moneyMobile">
              {" "}
              {formatSurplus(user?.data[0]?.surplus)}
            </span>
          </b>
        </div>
      </div>

      <div className="container">
        <div className="card border-0 mb-4 shadow-md">
          <div className="card-body bg-none py-3">
            <div className="marquee-container">
              <h4 className="marquee">
                <i className="fas fa-credit-card" aria-hidden="true"></i> Nạp
                tiền bằng Thẻ cào
              </h4>
            </div>
            <div className="tab-content">
              {" "}
              {/* Add the tab-content class here */}
              <form onSubmit={handleCardSubmit}>
                <div className="form-group animated-text">
                  <label htmlFor="pin">Mã thẻ</label>
                  <input
                    type="text"
                    id="pin"
                    value={pin}
                    onChange={(e) => setPin(e.target.value)}
                    className="form-control"
                    required
                    placeholder="Nhập mã thẻ"
                  />
                </div>

                <div className="form-group animated-text">
                  <label htmlFor="seri">Seri</label>
                  <input
                    type="text"
                    id="seri"
                    value={seri}
                    onChange={(e) => setSeri(e.target.value)}
                    className="form-control"
                    required
                    placeholder="Nhập seri"
                  />
                </div>

                <div className="form-group animated-text">
                  <label htmlFor="cardType">Loại thẻ</label>
                  <ListTypeCard
                    options={cardTypes}
                    selectedValue={cardType}
                    onSelect={handleCardTypeChange}
                  />
                </div>

                <div className="form-group animated-text">
                  <label htmlFor="cardValue">Mệnh giá</label>
                  <select
                    id="cardValue"
                    value={cardValue}
                    onChange={(e) => setCardValue(e.target.value)}
                    className="form-control"
                    required
                  >
                    <option value="" disabled>
                      Chọn mệnh giá
                    </option>
                    <option value="10000">10,000 VNĐ</option>
                    <option value="20000">20,000 VNĐ</option>
                    <option value="50000">50,000 VNĐ</option>
                    <option value="100000">100,000 VNĐ</option>
                    <option value="200000">200,000 VNĐ</option>
                    <option value="500000">500,000 VNĐ</option>
                  </select>
                </div>
                <button
                  type="submit"
                  className="custom-button animated-text"
                  disabled={loading}
                >
                  {loading ? (
                    "Đang xử lý..."
                  ) : (
                    <>
                      <i className="fas fa-wallet mx-1"></i> Nạp tiền
                    </>
                  )}
                </button>
              </form>
            </div>{" "}
            {/* End of tab-content */}
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <TransactionRecHistory userName={user?.data[0]?.UserName} />
        <NotificationContainer />
      </div>
    </div>
  );
}
